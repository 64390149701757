import React from 'react';
import scotchLogo from '../../../assets/loja-Scotch.webp';
import './footer.css';

const Footer = () => {
  const anoAtual = new Date().getFullYear();

  return (
    <div className='footer'>
      <div className='footer-img'>
        <img className='loja-scotch' src={scotchLogo} loading="eager"
          decoding="async" alt="Logo Scotch" />
      </div>
      <div className='footer-direitos'>
        <h4 className='direitos-autorais'>
          Todos os Direitos Reservados - Scotch Informática
        </h4>
        <h4 className='direitos-autorais'>
        <a href='https://prospectusnbn.com.br/bio' target='_blank' rel='noopener noreferrer'>
          Desenvolvido por PROSPECTUS NBN©️
          </a>&nbsp;- {anoAtual}
        </h4>
      </div>
    </div>
  );
}

export default Footer;
