import React from 'react';
import scotchLogo from '../../../assets/scotch-logo.webp';
import './logo.css';

const Logo = () => {
  return (
    <div className='logo' role="banner">
      <div className="pacman" aria-hidden="true"></div>
      <img src={scotchLogo} alt="Scotch Logo" loading="eager"
        decoding="async" />
    </div>
  );
}

export default Logo;
