import React, { useState } from 'react';
import './corporate.css';

const Corporate = () => {
  const [message, setMessage] = useState('');

  const handleWhatsAppClick = (newMessage) => {
    const phoneNumber = '5551999995365';
    const encodedMessage = encodeURIComponent(newMessage);
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    window.open(whatsappLink, '_blank'); // Abre o link em uma nova aba
    setMessage(''); // Limpa a mensagem após o clique
  };

  return (
    <div className='corporate'>
      <div className="box blue-background">
        <h2>Seu <span className='texto-verde'>notebook</span> e ou <span className='texto-verde'>celular</span> <br />parou de funcionar?
        </h2>
        <h2> Deixe-o em nossas mãos <br />e voltará como <span className='texto-verde'>novo!</span>
        </h2>
      </div>
      <div className="box image-background-1">
        <button
          onClick={() => {
            const newMessage = "Olá, estou interessado no serviço de DC Jack (conector de carga) para Notebook. Poderia me fornecer mais informações?";
            handleWhatsAppClick(newMessage);
          }}
        >
          <span className="circle1"></span>
          <span className="circle2"></span>
          <span className="circle3"></span>
          <span className="circle4"></span>
          <span className="circle5"></span>
          <span className="text">DC Jack (conector de carga) de Notebook</span>
        </button>
      </div>
      <div className="box image-background-2">
        <button
          onClick={() => {
            const newMessage = "Olá, estou interessado no serviço de Soldas BGA e Rebailing para Notebooks. Poderia me fornecer mais informações?";
            handleWhatsAppClick(newMessage);
          }}
        >
          <span className="circle1"></span>
          <span className="circle2"></span>
          <span className="circle3"></span>
          <span className="circle4"></span>
          <span className="circle5"></span>
          <span className="text">Soldas BGA e Rebailing para Notebooks</span>
        </button>
      </div>
      <div className="box image-background-3">
        <button
          onClick={() => {
            const newMessage = "Olá, estou interessado no serviço de Substituição de Display para Notebook. Poderia me fornecer mais informações?";
            handleWhatsAppClick(newMessage);
          }}
        >
          <span className="circle1"></span>
          <span className="circle2"></span>
          <span className="circle3"></span>
          <span className="circle4"></span>
          <span className="circle5"></span>
          <span className="text">Substituição de Display Notebook</span>
        </button>
      </div>
      <div className="box image-background-4">
        <button
          onClick={() => {
            const newMessage = "Olá, estou interessado no serviço de Conserto de fonte para Notebook. Poderia me fornecer mais informações?";
            handleWhatsAppClick(newMessage);
          }}
        >
          <span className="circle1"></span>
          <span className="circle2"></span>
          <span className="circle3"></span>
          <span className="circle4"></span>
          <span className="circle5"></span>
          <span className="text">Conserto de fonte para Notebook</span>
        </button>
      </div>
      <div className="box image-background-5">
        <button
          onClick={() => {
            const newMessage = "Olá, estou interessado no serviço de Conserto em geral para Celulares. Poderia me fornecer mais informações?";
            handleWhatsAppClick(newMessage);
          }}
        >
          <span className="circle1"></span>
          <span className="circle2"></span>
          <span className="circle3"></span>
          <span className="circle4"></span>
          <span className="circle5"></span>
          <span className="text">Conserto em geral para Celulares</span>
        </button>
      </div>
    </div>
  );
}

export default Corporate;
