import React from 'react'
import teclado from '../../../assets/teclado_logitech.webp';
import internetWifi from '../../../assets/internet-wifi.webp';
import internetWif2 from '../../../assets/internet-wifi.webp';
import './computers.css';

const Computers = () => {
  return (
    <div className='computers'>
      <div className='item-esquerda'>
        <img className='teclado' src={teclado} loading="eager"
          decoding="async" alt={teclado} />
      </div>
      <div className='item-direita'>
        <p><img className='internet' src={internetWifi} loading="eager"
          decoding="async" alt={internetWifi} />Para <b>computadores e periféricos</b>
          <br />
          a conexão é certa. Aqui é o <b>paraíso</b>
          <br />
          e a sua escolha é <b>esperta.</b> <img className='internet2' src={internetWif2} alt="" />
        </p>
      </div>
    </div>
  )
}

export default Computers
