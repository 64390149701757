import React from 'react'
import './home.css';
import Navbar from './navbar/Navbar';
import Intro from './intro/Intro';
import Logo from './logo/Logo';
import Computers from './computers/Computers';
import Banner from './banner/Banner';
import Grid from './grid/Grid';
import GamerBanner from './bannerGamer/BannerGamer';
import Corporate from './corporate/Corporate';
import Footer from './footer/Footer';
import ComeceAgora from './comeceAgora/ComeceAgora';
import RedesSociais from './redesSociais/RedesSociais';

const Home = () => {
  return (
    <div className='home'>
        <Navbar />
        <Intro />
        <Logo />
        <Computers />
        <Banner />
        <Grid />
        <GamerBanner />
        <Corporate />
        <ComeceAgora />
        <RedesSociais />
        <Footer />
    </div>
  )
}

export default Home