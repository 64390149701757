import React from 'react'
import './navbar.css';
import scotchLogo from '../../../assets/scotch-logo.webp';

const Navbar = () => {
  return (
    <nav>
      <img src={scotchLogo} loading="eager"
        decoding="async" alt="logo scotch estancia velha" />
    </nav>
  );
}

export default Navbar
