import React from 'react';
import notebook from '../../../assets/notebook.webp';
import gabinete from '../../../assets/gabinete.webp';
import './banner.css';

const Banner = () => {
  return (
    <div className='banner'>
      <div className='notebooks'>
        <div className='conteudo-esquerda'>
          <h2 className='texto-header'>Notebooks</h2>
          <p className='texto-p'>Está procurando um notebook que<br />combine desempenho com custo-benefício?</p>
        </div>
        <div className='conteudo-direita'>
          <img className='notebook' src={notebook} loading="eager"
            decoding="async" alt="notebook" />
        </div>
      </div>
      <div className='gamer'>
        <div className='conteudo-esquerda'>
          <img className='gabinete' src={gabinete} loading="eager"
            decoding="async" alt="gabinete" />
        </div>
        <div className='conteudo-direita'>
          <h2 className='texto-header'>Gamer</h2>
          <p className='texto-p'>Procurando um PC Gamer que leve<br />sua experiência de jogo ao extremo?</p>
        </div>
      </div>
    </div>
  );
};

export default Banner;
