import React from 'react';
import monitor_corp from '../../../assets/monitor-corp.webp';
import celular from '../../../assets/celular.webp';
import './bannerGamer.css';

const BannerGamer = () => {
  return (
    <div className='bannerGamer'>
      <div className='corporativos'>
        <div className='conteudo-esquerda'>
          <h2 className='texto-header'>Corporativos</h2>
          <p className='texto-p'>Está procurando um PC corporativo que alinhe<br />desempenho e confiabilidade?</p>
        </div>
        <div className='conteudo-direita'>
          <img className='monitor-corp' src={monitor_corp} loading="eager"
            decoding="async" alt="monitor corporativo" />
        </div>
      </div>
      <div className='smartphones'>
        <div className='conteudo-esquerda'>
          <img className='celular' src={celular} loading="eager"
            decoding="async" alt="smartphone" />
        </div>
        <div className='conteudo-direita'>
          <h2 className='texto-header'>Smartphones</h2>
          <p className='texto-p'>Procurando o smartphone perfeito para suas necessidades?</p>
        </div>
      </div>
    </div>
  );
};

export default BannerGamer;
