import React from 'react'
import { ReactTyped } from 'react-typed';
import diamond from '../../../assets/diamond.webp';
import gadget from '../../../assets/gadget.webp';
import atendimentoCliente from '../../../assets/atendimento-ao-cliente.webp';
import aorus from '../../../assets/aorus.webp';
import placaMae from '../../../assets/placa-mae.webp';
import fone from '../../../assets/fone.webp';
import noteServicos from '../../../assets/note-servicos.webp';
import cartucho from '../../../assets/cartucho.webp';
import './grid.css';

const Grid = () => {
  return (
    <div className='grid'>
      <div className='texto-borda'>
        <div className='box-grid'>
          <img src={diamond} loading="eager"
            decoding="async" alt={diamond} />
          <div className='texto-grid'>
            <h4>Alta Qualidade</h4>
            <p>A Scotch oferece produtos <br /> de altíssima qualidade para <br /> todos os públicos.</p>
          </div>
        </div>
        <div className='box-grid'>
          <img src={atendimentoCliente} loading="eager"
            decoding="async" alt="" />
          <div className='texto-grid'>
            <h4>Atendimento Especializado</h4>
            <p>Nossos atendentes são especialistas <br /> e estão sempre prontos para qualquer <br /> situação.</p>
          </div>
        </div>
        <div className='box-grid'>
          <img src={gadget} loading="eager"
            decoding="async" alt={gadget} />
          <div className='texto-grid'>
            <h4>Experiência</h4>
            <p>Possuímos experiência e <br /> confiabilidade em nossos <br /> serviços e produtos.</p>
          </div>
        </div>
      </div>
      <div className='img-background'>
        <div className="container">
          <div className='dual'>
            <div className="box">
              <div className="content">
                <h1>Hardware</h1>
                <p>Procurando hardware de alta performance para seu computador?</p>
              </div>
              <div className="image">
                <img src={placaMae} loading="eager"
                  decoding="async" alt={placaMae} />
              </div>
            </div>
            <div className="box">
              <div className="content">
                <h1>Periféricos</h1>
                <p>Está em busca dos melhores periféricos para turbinar o seu setup?</p>
              </div>
              <div className="image">
                <img src={fone} alt={fone} />
              </div>
            </div>
          </div>
          <div className='dual'>
            <div className="box">
              <div className="content">
                <h1>Recargas</h1>
                <p>Precisando recarregar os cartuchos da sua impressora?</p>
              </div>
              <div className="image">
                <img src={cartucho} loading="eager"
                  decoding="async" alt={cartucho} />
              </div>
            </div>
            <div className="box">
              <div className="content">
                <h1>Serviços</h1>
                <p>Serviços essenciais para garantir o funcionamento do seu computador.</p>
              </div>
              <div className="image">
                <img src={noteServicos} loading="eager"
                  decoding="async" alt={noteServicos} />
              </div>
            </div>
          </div>
        </div>
        <div className='background-frase'>
          <ReactTyped
            strings={['CHEGOU NA SCOTCH, JÁ ESTÁ NO LUGAR, COM HARDWARE DE QUALIDADE PRA VOCÊ MONTAR. PLACA-MÃE, PROCESSADOR É TUDO QUE PRECISA, COM AS MELHORES MARCAS, AQUI SUA BUSCA FINALIZA.'
            ]}

            typeSpeed={75}
            backSpeed={20}
            loop
            className="frase"
          />
        </div>
        <img className='aorus' src={aorus} loading="eager"
          decoding="async" alt={aorus} />
      </div>
    </div>
  )
}

export default Grid
