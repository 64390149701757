import React from 'react';
import './comeceAgora.css';

const ComeceAgora = () => {
  const phoneNumber = "5551999995365";
  const message = "Olá, preciso de ajuda na área de informática. Poderia me ajudar?";
  const encodedMessage = encodeURIComponent(message);
  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

  const handleWhatsAppClick = () => {
    window.open(whatsappLink, '_blank'); // Abre o link do WhatsApp em uma nova aba
  };

  return (
    <div className='comeceAgora'>
        <h1>COMECE COM A SCOTCH AGORA!</h1>
        <h2>Clique no botão abaixo!</h2>
      <button
        onClick={handleWhatsAppClick}
      >
        <span className="circle1"></span>
        <span className="circle2"></span>
        <span className="circle3"></span>
        <span className="circle4"></span>
        <span className="circle5"></span>
        <span className="text">Iniciar conversa</span>
      </button>
    </div>
  );
}

export default ComeceAgora;
