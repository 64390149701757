import React, { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import game from '../../../assets/game.webp';
import gameController from '../../../assets/game-controller.webp';
import ghost from '../../../assets/ghost.webp';
import laptop from '../../../assets/laptop.webp';
import './intro.css';

const WhatsAppButton = lazy(() => import('../../WhatsAppButton/WhatsAppButton'));

const Intro = () => {
  const phoneNumber = "5551999995365";
  const message = "Olá, preciso de ajuda na área de informática. Poderia me ajudar?";
  const encodedMessage = encodeURIComponent(message);
  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

  const handleWhatsAppClick = () => {
    window.open(whatsappLink, '_blank', 'noopener,noreferrer');
  };

  return (
    <>
      <Helmet>
        <title>Scotch Informática - Tecnologia de Ponta</title>
        <meta name="description" content="Scotch Informática oferece tecnologia de ponta para todas as suas necessidades em informática." />
        <link rel="preload" as="image" href={game} />
        <link rel="preload" as="image" href={gameController} />
        <link rel="preload" as="image" href={ghost} />
        <link rel="preload" as="image" href={laptop} />
      </Helmet>
      <div className='intro'>
        <div className='top-icons'>
          <img className='game' src={game} alt="Ícone de jogo" loading="lazy" />
          <img className='gameController' src={gameController} alt="Controle de jogo" loading="lazy" />
        </div>
        <h1>
          É NA <span className='highlight'>SCOTCH</span>
          <br />
          ONDE TUDO ACONTECE
          <br />
          TECNOLOGIA DE PONTA
          <br />
          A GENTE MERECE
        </h1>
        <div className='bottom-icons'>
          <img className='ghost' src={ghost} alt="Ícone de fantasma" loading="lazy" />
          <img className='laptop' src={laptop} alt="Ícone de laptop" loading="lazy" />
        </div>
        <button onClick={handleWhatsAppClick}>
          <span className="circle1"></span>
          <span className="circle2"></span>
          <span className="circle3"></span>
          <span className="circle4"></span>
          <span className="circle5"></span>
          <span className="text">Iniciar conversa</span>
        </button>
        <Suspense fallback={<div>Carregando...</div>}>
          <WhatsAppButton />
        </Suspense>
      </div>
    </>
  );
}

export default Intro;
